body {
  margin: 0;
  font-family: "TTFors", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

html {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

* {
  //scroll-behavior: smooth;
}

#root {
  height: 100vh;
  height: 100svh;
  height: 100dvh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
}

@media screen and (max-width: 1024px) {
  #root {
    height: 100vh;
    height: 100dvh;
  }
}

.win-upscale {
  h1,
  span:not(.nomore),
  a,
  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  .langsx>div {
    transform: translateY(-4px);
    line-height: 1;
    &::before {
      bottom: -4px !important;
    }
  }
}

.rsis-container {
  div {
    transition-timing-function: cubic-bezier(1, -0.03, 0.65, 0.9) !important;
    background-position: center !important;
  }
}

@media screen and (max-width: 1328px) {
  .global_wrap {
    // margin-bottom: 55px !important;
  }
}

@media (min-width: 600px) and (max-width: 1430px) {
  .row {
    padding-left: 30px;
    padding-right: 30px;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

* {
  scroll-behavior: smooth;
}

 ::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

 ::-webkit-scrollbar-thumb {
  background-color: #babac07c;
  border-radius: 16px;
}

 ::-webkit-scrollbar-track:hover {}

 ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

 ::-webkit-scrollbar {
  width: 1px;
  max-height: 1px;
  &:focus,
  &:focus-visible,
  &:hover,
  &:active {
    width: 10px !important;
    max-height: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  .win-upscale {}
  .marked {
    position: relative;
    left: -9px;
    width: fit-content;
  }
}

.ant-float-btn-circle {
  width: 52px !important;
  height: 52px !important;
}

.ant-float-btn-group {
  width: 52px !important;
  z-index: 99999999999 !important;
}

.ant-float-btn-body {
  width: 52px !important;
  height: 52px !important;
}

.ant-float-btn-default .ant-float-btn-body {
  background-color: #ffffff80 !important;
  backdrop-filter: blur(10px) !important;
}

.ant-float-btn-primary .ant-float-btn-body {
  background-color: #008090 !important;
}

.ant-float-btn-icon {
  width: 24px !important;
  font-size: 24px !important;
}

.fader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  top: 0px;
  left: 0px;
  background-color: #a2a2a2;
  transition: all 0.6s ease;
  z-index: 9999999999999;
  div {
    height: 100vh;
    height: 100svh;
    justify-content: center;
    display: flex;
    img {
      z-index: 9999;
      width: 300px;
      height: 100%;
      transition: all 1s ease;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered {
  display: flex;
  align-items: center;
}

.loading {
  text-align: center;
  padding: 240px 0 0;
}

.swiper {
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .swiper {
    padding-bottom: 40px;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
  transition: all 0.1s ease;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  border: 2px solid #fff !important;
  padding: 5px !important;
}

.modal_text {
  font-size: 16px;
  color: #000;
  padding-bottom: 4px;
}

.modal_text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

.modal_text p {
  margin-top: -8px;
  text-align: left;
}

.modal_action {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  z-index: 999999999;
  background-color: #fff;
  padding: 0px 16px 0px 16px;
  &.modal_actionx {
    padding: 0px;
    align-items: flex-start;
    margin-top: 0px;
  }
}

.tox-menu {
  z-index: 99999999999 !important;
}

.tox {
  z-index: 9999999999999 !important;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal_action button {
  color: #000000;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  font-weight: 500;
}

.modal_action a {
  color: #000000;
  font-weight: 500;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

.modal_action button:first-child {
  color: #fff;
  font-weight: 500;
  background: #000 !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  border-radius: 8px;
  margin-bottom: 0px;
  padding: 14px 24px 12px;
  line-height: 1;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.modal_form {
  div {
    width: 100%;
    margin-bottom: 16px;
    label {
      font-size: 18px;
      margin-bottom: 6px;
      small {
        color: red;
      }
    }
    input,
    textarea {
      width: 100%;
      border: 3px solid #C6B99D;
      color: rgb(0, 0, 0);
      padding: 8px 12px;
      background-color: transparent;
      border-radius: 0px;
      outline: none;
      font-size: 18px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.awssld__container {
  padding-bottom: 57% !important;
}

.awssld__controls button {
  width: 4% !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background: #fff !important;
}

.awssld__bullets {
  bottom: 30px !important;
  z-index: 999999999 !important;
}

.awssld__bullets .awssld__bullets--active {
  background: #fff !important;
}

.awssld__bullets button {
  background: #ffffff4a !important;
}

.padded_content {
  padding-top: 120px;
  padding-bottom: 42px;
}

.xbtn_sl {
  opacity: 0.7;
  &:hover {
    opacity: 1;
    // width: 50px !important;
  }
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  &.light {
    margin-bottom: 32px !important;
    .breadcrumbs_item {
      color: rgba(255, 255, 255, 0.5) !important;
      &:last-child {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  .breadcrumbs_item {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 4px;
    &:first-child {
      margin-left: 4px;
      cursor: pointer !important;
    }
    &:last-child {
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.page_title {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #242424;
  &.light {
    color: #fff;
  }
}

.page_subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  &.light {
    color: #fff;
  }
}

.page_desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  &.light {
    color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .awssld__container {
    padding-bottom: 160% !important;
  }
}

.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px !important;
}

@media screen and (min-width: 320px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 960px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px !important;
  }
}

@media screen and (min-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1420px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px !important;
  }
}

@media screen and (min-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px !important;
  }
}

.ant-collapse {
  border: none !important;
  border-radius: 0px !important;
  .ant-collapse-header-text {
    //color: #fff !important;
    font-size: 22px !important;
    font-weight: 500;
  }
  .ant-collapse-expand-icon {
    //color: #fff !important;
    height: 31px !important;
    span {
      font-size: 18px !important;
      svg {}
    }
  }
  .ant-collapse-content {
    background-color: transparent !important;
    border-top: none !important;
    //color: #fff !important;
    .ant-collapse-content-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      opacity: 0.8;
      p {
        //color: #fff !important;
      }
    }
  }
  .ant-collapse-header {
    //flex-direction: row-reverse !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .ant-collapse-header-text {
      font-size: 20px !important;
    }
  }
  .ant-collapse-item {
    border-radius: 0px !important;
    &:last-child {
      border-bottom: none !important;
    }
  }
}

.headSlider {
  width: 100% !important;
  position: absolute;
  img {
    width: 100%;
  }
  .hs_sl {
    height: 630px;
    width: 100%;
    div {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 630px;
    }
  }
}

.foodSlider {
  width: 101.3% !important;
  img {
    width: 90%;
  }
}

.arhbtn {
  margin-top: 32px;
  background-color: #C8B79D;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 42px;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  &.arhbtn-primary {
    background-color: #dfa25f;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
  }
  &.arhbtn-round {
    background-color: #dfa25f;
    color: #fff;
    border-radius: 100px;
    width: 100%;
  }
}

.btnb {
  background-color: #ffc064 !important;
  color: #1f1d1d;
  padding: 11px 20px;
  font-size: 22px;
  font-weight: 300;
  border: none;
  outline: none;
  display: inline-block;
  width: fit-content;
  text-decoration: none;
  font-family: "Yanone Kaffeesatz", Arial, Tahoma, sans-serif !important;
  margin-bottom: 16px;
  &:hover {
    color: #1f1d1d !important;
  }
}

table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  tr {
    &:first-child {
      td {
        background: none;
        border-width: 0 1px 1px 0;
        box-shadow: inset 0px 4px 3px -2px rgba(0, 0, 0, .06);
      }
    }
    td {
      padding: 10px;
      text-align: left;
      border-width: 1px;
      border-style: solid;
      vertical-align: middle;
      font-size: 22px;
      font-weight: 300;
      border-color: rgba(0, 0, 0, .08);
      font-family: "Yanone Kaffeesatz", Arial, Tahoma, sans-serif !important;
      strong {
        font-weight: 700;
      }
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.ant-menu {
  background: transparent !important;
  border-inline-end: none !important;
  .ant-menu-submenu-title {
    background-color: transparent;
    color: #fff !important;
    padding-left: 0px !important;
    font-weight: 700 !important;
    &:active,
    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
  .ant-menu-item-selected {
    background: transparent !important;
  }
  .ant-menu-item {
    padding-left: 0px !important;
    span {
      color: #fff !important;
      font-weight: 500 !important;
    }
  }
}

.ant-upload-select {
  display: block !important;
}

.ant-message {
  z-index: 999999999999 !important;
}

#rsisi {
  div:not(.rsis-container) {
    overflow: unset !important;
  }
  .rsis-container {
    overflow: hidden !important;
  }
}

.global_wrap {
  padding-top: 72px;
  margin-bottom: 110px;
  overflow: hidden;
}

.contact-info-block {
  .contacts-grid {
    padding-top: 0;
    display: flex;
    transition: all 0.4s ease;
    .contact-info {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 45px;
      display: flex;
      a {
        color: #000;
        padding-bottom: 4px;
        font-family: "TTFors", sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.4s ease;
        bottom: 0px;
      }
    }
  }
}

.contacts-links {
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: static;
  a {
    transition: all 0.4s ease;
  }
}

.contacts-link-info-right {
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-right: 40px;
  font-family: "TTFors", sans-serif;
  font-size: 11px;
  text-transform: capitalize;
  text-decoration: none;
  transition: border-width .2s;
  &:hover {
    color: #000;
  }
}

@media screen and (max-width: 960px) {
  .global_wrap {
    // padding-top: 80px;
    // margin-bottom: 80px;
  }
  .contacts-grid {
    flex-direction: column;
    .contact-info {
      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .global_wrap {
    padding-top: 61px;
    margin-bottom: 90px;
  }
  .fader {
    div {
      img {
        width: 160px !important;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .contact-info-block {
    padding-left: 18px;
  }
  .info-contacts {
    padding-bottom: 20px !important;
    font-size: 15px !important;
    line-height: 1;
  }
  .contact-info {
    margin-right: 80px !important;
  }
  .contacts-link-info-right {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 1600px) {
  .global_wrap {
    // padding-top: 78px;
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1920px) {
  .global_wrap {
    // padding-top: 80px;
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 960px) {
  .hrefed {
    position: relative;
    &.grey {
      &::before {
        background-color: #969696 !important;
      }
    }
    &::before {
      content: " ";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0px;
      height: 1px;
      background-color: #fff;
      transition: all 0.3s ease;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
  .hrefeds {
    span {
      width: fit-content;
      position: relative;
      &.grey {
        &::before {
          background-color: #969696 !important;
        }
      }
      &::before {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: #fff;
        transition: all 0.3s ease;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

.tox .tox-statusbar__help-text {
  font-size: 7px !important;
  line-height: 2.2 !important;
}