.header {
  position: fixed;
  width: 100%;
  z-index: 999999;
  transition: all 0.6s ease;
  padding: 26px 0;
  background-color: rgba(255, 255, 255, 1);
  // backdrop-filter: blur(5px);
  // overflow-y: scroll;
  top: 0;
  &.fixed {
    //box-shadow: 0 6px 10px rgb(0 0 0 / 5%);
    top: -80px;
    .menu {
      .menu_item {
        //color: rgba(0, 0, 0, 0.8);
        &:hover {
          //color: #000;
        }
      }
    }
  }
  &.noblur {
    // backdrop-filter: blur(0px) !important;
  }
  &.nfilled {
    // background-color: transparent !important;
    .menu_item {
      color: #fff !important;
    }
    .langs {
      div {
        color: #E0E0E0 !important;
        &.active {
          color: #fff !important;
        }
      }
    }
  }
  &.tgl {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  .logo {
    display: block;
    width: 100%;
    text-align: left;
    line-height: 1;
    a {
      line-height: 1;
    }
    img {
      width: 85px;
      transition: all 0.4s ease;
      position: relative;
      top: 1px;
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding: 20px 0;
    .menu_item {
      min-width: fit-content;
      margin-right: 40px;
      cursor: pointer;
      transition: color 0.3s ease;
      user-select: none;
      position: relative;
      text-decoration: none;
      font-size: 20px;
      font-weight: 400;
      line-height: 1;
      color: #000000;
      &:hover {
        color: #000000;
      }
      &.active {
        color: rgba(255, 255, 255, 1);
      }
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
  .ended {
    display: flex;
    flex-direction: row;
    align-items: center;
    .phones {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
      margin-right: 28px;
      text-shadow: 0 1px 6px rgba(0, 0, 0, 0.9);
    }
    .backcall {
      button {
        background: #FFFFFF;
        border-radius: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #183F6C;
        padding: 12px 18px;
        outline: none;
        border: none;
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      display: block;
      font-size: 12px;
      margin-right: 10px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.7);
      transition: all 0.3s ease;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-right: 0px;
      }
      svg {}
      img {
        width: 36px;
      }
    }
  }
  .contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 32px;
    a {
      display: block;
      min-width: fit-content;
      text-decoration: none;
      &:nth-child(1) {
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.7);
        transition: all 0.3s ease;
        &:hover {
          color: #fff;
        }
        span {
          color: #fff;
          margin-right: 8px;
        }
      }
      img {}
    }
  }
}

.flxd {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.ending {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  .langs {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    div {
      color: #9E9E9E;
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer;
      line-height: 1;
      user-select: none;
      transition: all 0.3s ease;
      &:hover {
        color: #000;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.active {
        color: #000;
      }
    }
  }
}

.centere {
  align-items: center;
  display: flex;
}

.menu_open {
  color: #000;
  font-size: 28px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  z-index: 999999999999999;
  top: 18px;
  right: 5px;
  transition: all 0.4s ease;
  &.tgl {
    top: -100px;
  }
  svg {
    rect,
    path {
      transition: all 0.3s ease;
      transform: translateY(0px) translateX(0px) rotate(0deg);
    }
  }
  &.openned {
    svg {
      rect,
      path {
        &:nth-child(1) {
          transform: translateY(15px) translateX(-2px) rotate(-45deg);
        }
        &:nth-child(2) {
          transform: translateY(-4px) translateX(12px) rotate(45deg);
        }
      }
    }
  }
}

.mob_menu {
  display: block;
  opacity: 1;
  width: 100vw;
  height: 294px;
  overflow: hidden;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  z-index: 99999999999;
  transition: all 0.3s ease;
  &.winter {
    background-color: rgba(99, 132, 156, 0.85) !important;
  }
  &.fixedd {
    .mm_w_logo {
      img {
        width: 80px !important;
      }
    }
    .mm_wrap {
      padding: 22px 15px !important;
    }
    .mm_w_close {
      top: 22px !important;
      right: 23px !important;
    }
  }
  .mm_wrap {
    padding: 1px 20px 26px;
    position: relative;
    height: 100%;
    .mm_w_close {
      position: absolute;
      right: 0px !important;
      top: 16px;
      font-size: 28px;
      color: #fff;
      z-index: 9999999999;
    }
    .mm_w_logo {
      position: absolute;
      margin-bottom: 74px;
      top: 0px;
      left: 0px;
      img {
        width: 60px;
        position: relative;
        // top: -6px;
      }
    }
    .mm_w_langs {
      position: absolute;
      bottom: 38px;
      display: flex;
      flex-direction: row;
      right: 20px;
      div {
        font-size: 13px;
        font-family: 'TTFors';
        margin-right: 7px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6);
        transition: all 0.3s ease;
        &.active,
        &:hover {
          color: #000;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .mm_w_contacts {
      position: absolute;
      bottom: 40px;
      display: flex;
      flex-direction: row;
      right: 65px;
      a {
        display: block;
        color: #000;
        text-decoration: none;
        font-size: 11px;
        font-weight: 400;
        line-height: 1;
        margin-right: 42px;
        font-family: 'Organetto semiext', sans-serif;
        transition: all 0.4s ease;
        &:last-child {
          margin-right: 0px !important;
        }
        &:hover {
          color: rgb(235, 98, 22);
        }
      }
    }
    .mm_w_items {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow-y: scroll;
      margin-top: 70px;
      a {
        display: block;
        color: rgb(0, 0, 0);
        text-decoration: none;
        font-size: 23px;
        font-weight: 400;
        margin-bottom: 6px;
        text-transform: uppercase;
        cursor: pointer;
        font-family: 'TTFors', sans-serif;
        text-decoration: none;
        transition: all 0.4s ease;
        svg {
          width: 0px;
          transition: all 0.4s ease;
        }
        &:hover {
          color: rgb(235, 98, 22) !important;
          svg {
            width: 49px !important;
            padding-left: 20px;
          }
        }
        &.active {
          color: #000;
        }
      }
    }
  }
}

.mn_dp_open {
  position: relative;
  z-index: 9999;
  transition: all 0.4s ease;
  cursor: pointer;
  line-height: 1;
  svg {
    rect,
    path {
      transition: all 0.3s ease;
      transform: translateY(0px) translateX(0px) rotate(0deg);
    }
  }
  &.openned {
    svg {
      rect,
      path {
        &:nth-child(1) {
          transform: translateY(14.5px) translateX(-2px) rotate(-45deg);
        }
        &:nth-child(2) {
          transform: translateY(-4px) translateX(11.5px) rotate(45deg);
        }
      }
    }
  }
}

.drop_menu {
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  &.openned {
    overflow-y: unset;
    overflow-x: hidden;
    .mask {
      background-color: #fff;
      width: 102%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -1%;
      z-index: 99;
    }
  }
  .mask {}
  .dm_langs {
    position: relative;
    display: flex;
    bottom: -14px;
    flex-direction: row;
    right: 0px;
    z-index: 999;
    margin: 0 0 0 auto;
    width: fit-content;
    div {
      font-size: 14.3px;
      font-family: 'TTFors';
      margin-right: 7px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);
      transition: all 0.3s ease;
      &.active,
      &:hover {
        color: #000;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .dm_items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // overflow-y: scroll;
    margin-top: 30px;
    position: relative;
    z-index: 999;
    &.mp {
      a {
        margin-bottom: 12px !important;
      }
    }
    a {
      display: block;
      color: rgb(0, 0, 0);
      text-decoration: none;
      font-size: 23px;
      font-weight: 400;
      margin-bottom: 12px;
      text-transform: uppercase;
      cursor: pointer;
      font-family: 'TTFors', sans-serif;
      text-decoration: none;
      transition: all 0.4s ease;
      svg {
        width: 0px;
        transition: all 0.4s ease;
      }
      &.active {
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 20px 0;
    .logo {
      position: relative;
      z-index: 9999;
      img {
        width: 80px !important;
      }
    }
  }
  .drop_menu {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    .dm_items {
      padding-right: 16px;
      margin-top: 85px;
      a {
        &:hover {
          color: rgb(0, 0, 0) !important;
          svg {
            width: 0px !important;
            padding-left: 0px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    .menu_item {
      padding: 10px 0 !important;
      font-size: 12px !important;
    }
  }
  .langs {
    div {
      font-size: 12px !important;
    }
  }
  .logo {
    img {
      width: 35px !important;
    }
  }
  .dm_langs {
    right: 17px !important;
  }
}

@media screen and (max-width: 960px) {
  .menu {
    padding: 10px 0 !important;
    .menu_item {
      font-size: 16px !important;
    }
  }
  .langs {
    div {
      font-size: 16px !important;
    }
  }
  .logo {
    img {
      // width: 50px !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .menu_open {
    //display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .nfilled {
    .menu_item {
      span {
        &::before {
          background-color: rgb(255, 255, 255);
        }
      }
    }
    .langs {
      div {
        &::before {
          background-color: rgb(255, 255, 255);
        }
      }
    }
  }
  .menu_item {
    span {
      &::before {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: rgb(0, 0, 0);
        transition: all 0.3s ease;
      }
    }
    &:hover {
      span {
        &::before {
          width: 100%;
        }
      }
    }
  }
  .langs {
    div {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: rgb(0, 0, 0);
        transition: all 0.3s ease;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1380px) {
  .dm_items {
    a {
      &:hover {
        color: rgb(235, 98, 22);
        svg {
          width: 49px;
          padding-left: 20px;
        }
      }
    }
  }
  .logo {
    img {
      &:hover {
        transform: scale(0.9);
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .menu {
    padding: 20px 0 !important;
    .menu_item {
      font-size: 20px !important;
    }
  }
  .langs {
    div {
      font-size: 20px !important;
    }
  }
  .logo {
    img {
      width: 80px !important;
    }
  }
}

@media screen and (min-width: 1920px) {
  .menu {
    padding: 32px 0 !important;
    .menu_item {
      font-size: 22px !important;
      span {
        &::before {
          bottom: 0;
        }
      }
    }
  }
  .fixed {
    top: -126px !important;
  }
  .langs {
    div {
      font-size: 22px !important;
    }
  }
  .logo {
    img {
      width: 100px !important;
    }
  }
}

@media screen and (min-width: 1280px) {
  .mm_w_items {
    padding-right: 0px !important;
  }
  .mm_w_contacts {
    a {
      font-size: 13px !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  .mm_w_items {
    padding-right: 0px !important;
  }
}