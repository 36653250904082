.footer {
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 25px;
  transition: all 0.4s ease;
  position: relative;
}

@media (min-width: 600px) and (max-width: 1430px) {
  .footer {
    padding-bottom: 35px !important;
  }
}

.footer_wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
  .footer_copy {
    font-size: 17px;
    line-height: 110%;
    font-family: 'TTFors', sans-serif;
    // text-transform: capitalize;
  }
  .footer_socials {
    z-index: 2;
    position: relative;
    a {
      font-size: 17px;
      color: #000;
      margin-right: 42px;
      text-decoration: none;
      text-transform: capitalize;
      font-family: 'TTFors', sans-serif;
      outline: none;
      line-height: 110%;
      transition: color .2s ease-out;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
}

.to_top {
  color: #d2a367;
  background-color: #1f201f;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  text-align: center;
  font-size: 26px;
  padding-top: 6px;
  transition: all 0.3s ease;
  z-index: 9999999;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 768px) {
  .to_top {
    bottom: 20px !important;
    right: 20px !important;
  }
  .footer_copy {
    font-size: 15px !important;
    // top: -4px !important;
  }
  .footer_socials {
    a {
      color: #000;
      margin-right: 28px !important;
      font-size: 15px !important;
      &:hover {
        color: #000 !important;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .f_block {
    margin-top: 32px !important;
  }
  .lgs {
    margin-top: -22px;
  }
}

@media screen and (max-width: 960px) {
  .footer {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 26px;
  }
}

@media screen and (max-width: 500px) {}

@media screen and (min-width: 1380px) {
  .footer {
    padding-bottom: 40px !important;
  }
  .footer_socials {
    a {
      &:hover {
        color: #eb6216;
      }
    }
  }
}

@media screen and (min-width: 1600px) {}

@media screen and (min-width: 1920px) {
  .footer {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
}

@media screen and (min-height: 1250px) {
  .ftrx {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
}