.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999999999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.7);
  animation-name: appear;
  animation-duration: 300ms;
  transition: all 0.3s ease;
  * {
    font-family: "HelveticaNeueCyr", "NeueHaas", sans-serif;
  }
}

.modal_dialog {
  width: 70%;
  max-width: 100% !important;
  background: #ffffff;
  position: relative;
  margin: 0 0px;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // border-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 4px 38px 0 rgb(0 0 0 / 12%), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
}

.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
  padding: 12px 16px 0px;
}

.modal_header {
  justify-content: space-between;
  position: relative;
  .modal_header__title {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: left !important;
    color: #13171D;
    width: 100%;
    padding-top: 4px;
  }
  .modal_header__close {
    cursor: pointer;
    padding: 0px 8px;
    color: rgba(0, 0, 0, 0.3);
    font-size: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.modal_body {
  overflow: auto;
  overflow-y: scroll;
  .modal_body__content {
    padding: 16px;
  }
}

@media screen and (max-width: 960px) {
  .modal_dialog {
    max-height: calc(100vh - 140px) !important;
  }
}

@media screen and (min-width: 1024px) {
  .modal_body {
    padding: 0 24px;
  }
  .modal_header__title {
    width: 100%;
    //padding-left: 24px;
  }
}

.modal_footer {
  border-top: 1px solid #2a2a2a;
  justify-content: flex-end;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}