.manager_btn {
  position: fixed;
  z-index: 9999999;
  right: 0px;
  top: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(235, 235, 235);
  padding: 4px 4px 4px 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  &:hover {
    padding: 4px 14px 4px 4px;
  }
  div {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    width: 42px;
    opacity: 0.6;
    text-align: center;
    transition: all 0.4s ease;
    cursor: pointer;
    font-size: 18px;
    padding: 6px 0 3px;
    // line-height: 1;
    img {
      width: 22px;
      position: relative;
      top: -3px;
    }
    &:hover {
      opacity: 1;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.descript {
  width: 100% !important;
  font-size: 15px !important;
  color: rgba(0, 0, 0, 0.7) !important;
  a {
    font-weight: 500;
  }
}

.manager {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  padding: 32px;
  transition: all 0.3s ease;
  * {
    font-family: "TTFors", sans-serif;
  }
  h1,
  span:not(.nomore),
  a,
  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  .langsx>div {
    transform: translateY(0px) !important;
    //line-height: unset !important;
    &::before {
      bottom: 0px !important;
    }
  }
  .manager_wrap {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 1380px;
    margin: 0 auto;
    .manager_sidebar {
      height: 100%;
      width: 300px;
      min-width: 300px;
      background-color: #000;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      position: relative;
      background-image: url("./images/warcraft.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0px 85%;
      .manager_sidebar_cwp {
        position: absolute;
        bottom: 72px;
        color: #fff;
        padding: 5px 16px;
        margin: 0px 18px;
        cursor: pointer;
        width: fit-content;
        transition: all 0.3s ease;
        border-radius: 4px;
        font-size: 15px;
        font-family: "TTFors", sans-serif;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        &.active {
          background-color: rgba(255, 255, 255, 0.13);
        }
        svg {
          width: 24px;
          text-align: left;
        }
      }
      .manager_sidebar__logout {
        position: absolute;
        bottom: 20px;
        color: #fff;
        padding: 5px 16px;
        margin: 0px 18px;
        cursor: pointer;
        width: fit-content;
        transition: all 0.3s ease;
        border-radius: 4px;
        font-size: 15px;
        font-family: "TTFors", sans-serif;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        &.active {
          background-color: rgba(255, 255, 255, 0.13);
        }
        svg {
          width: 24px;
          text-align: left;
        }
      }
      .manager_sidebar__logo {
        padding: 16px 18px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        position: relative;
        font-family: "TTFors", sans-serif;
        b {
          font-size: 12px;
          position: absolute;
          top: 17px;
          margin-left: 6px;
          font-family: "TTFors", sans-serif;
        }
      }
      .manager_sidebar__menu {
        padding: 0 18px;
        margin-top: 24px;
        div {
          color: #fff;
          margin-bottom: 10px;
          padding: 5px 16px;
          cursor: pointer;
          width: fit-content;
          min-width: fit-content;
          transition: all 0.3s ease;
          border-radius: 4px;
          font-size: 18px;
          font-family: "TTFors", sans-serif;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &.active {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    .manager_body {
      height: 100%;
      width: 100%;
      position: relative;
      overflow-x: hidden;
      .manager_body__header {
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
        padding: 16px 20px;
        position: relative;
        .mbh_title {
          div {
            &:nth-child(1) {
              font-size: 20px;
              font-weight: 600;
              font-family: "TTFors", sans-serif;
            }
            &:nth-child(2) {
              font-size: 16px;
              font-weight: 400;
              margin-top: 0px;
              opacity: 0.6;
            }
          }
        }
        .mbh_close {
          position: absolute;
          top: 28px;
          right: 32px;
          font-size: 26px;
          opacity: 0.6;
          transition: all 0.3s ease;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }
      .manager_body__content {
        padding: 24px 10px;
        height: calc(100% - 93px);
        overflow-y: scroll;
        .setting_block {
          position: relative;
          h3 {
            font-size: 24px;
            font-weight: 700;
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: #2f2f2f;
            width: 320px;
          }
          .setting_block__toggle {
            margin-bottom: 20px;
            .select {
              background-color: transparent;
              border: 1px solid #d8d8d8;
              border-radius: 8px;
              padding: 6px 10px;
              width: 100%;
              max-width: 250px;
              select {
                border: none;
                outline: none;
                width: 100%;
              }
            }
          }
          button {
            background-color: #84aac5;
            color: #fff;
            border-radius: 12px;
            padding: 10px 38px;
            font-size: 18px;
            outline: none;
            border: none;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
            transition: all 0.3s ease;
            &:hover {
              background-color: #96bbd6;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
            }
            &:disabled {
              background-color: #d3e7f6;
              color: #fff;
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
}

.indev {
  font-size: 26px;
  padding-left: 10px;
  text-transform: uppercase;
  color: #00000075;
}

.xbtn {
  background-color: #84aac5;
  color: #fff;
  border-radius: 12px;
  padding: 10px 38px;
  font-size: 18px;
  outline: none;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;
  &:hover {
    background-color: #96bbd6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }
  &:disabled {
    background-color: #d3e7f6;
    color: #fff;
    box-shadow: none !important;
  }
}

.grid_block {
  position: relative;
  h3 {
    font-size: 24px;
    font-weight: 700;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #2f2f2f;
    width: 320px;
  }
}

.xtabs {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border-radius: 8px;
  background-color: #ebebeb;
  padding: 4px;
  margin-top: 12px;
  margin-bottom: 20px;
  .xtabs_item {
    width: 200px;
    min-width: 200px;
    text-align: center;
    border-radius: 8px;
    color: rgb(47, 47, 47);
    position: relative;
    font-size: 16px;
    font-weight: 400;
    z-index: 99;
    padding: 4px 0;
    cursor: pointer;
    span {}
  }
  &::before {
    content: " ";
    position: absolute;
    height: calc(100% - 8px);
    width: 200px;
    left: 4px;
    top: 4px;
    border-radius: 8px;
    background-color: #fff;
    transition: all 0.3s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }
  &.active2 {
    &::before {
      left: 204px;
    }
  }
  &.active3 {
    &::before {
      left: 408px;
    }
  }
  &.minimized {
    .xtabs_item {
      width: 140px !important;
      min-width: 140px !important;
    }
    &::before {
      width: 140px !important;
    }
    &.active2 {
      &::before {
        left: 144px !important;
      }
    }
    &.active3 {
      &::before {
        left: 284px;
      }
    }
    &.active4 {
      &::before {
        left: 424px;
      }
    }
    &.active5 {
      &::before {
        left: 564px;
      }
    }
    &.active6 {
      &::before {
        left: 704px;
      }
    }
  }
}

.xgrid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  .xgrid_item {
    width: 33.3%;
    padding: 0 16px 0 0;
    margin-bottom: 16px;
    .xcard {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #fff;
      box-shadow: 0 2px 6px rgba(0, 0, 0, .12);
      padding: 10px 12px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, .15);
      }
      .xcard_image {
        min-width: 64px;
        width: 64px;
        height: 64px;
        border-radius: 8px;
        margin-right: 16px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .xcard_data {
        width: 100%;
        .xcard_data__id {}
        .xcard_data__title {
          font-size: 18px;
          line-height: 19px;
          font-weight: 600;
        }
        .xcard_data__st {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
        }
      }
      .xcard_actions {
        opacity: 0.2;
      }
    }
  }
}

.xactions {
  position: absolute;
  top: 42px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  .xactions_item {
    width: fit-content;
    text-align: center;
    border-radius: 8px;
    color: rgb(47, 47, 47);
    background-color: #ebebeb;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    z-index: 99;
    padding: 8px 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    transition: all 0.3s ease;
    margin-right: 12px;
    &:last-child {
      margin-right: 0px !important;
    }
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
    }
    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    }
    &.danger {
      color: rgb(255, 255, 255);
      background-color: #c20b0b;
    }
    &.success {
      color: rgb(255, 255, 255);
      background-color: #0bc212;
    }
    &.disabled {
      opacity: 0.4;
      cursor: auto;
      user-select: none;
      pointer-events: none;
    }
    svg {
      margin-right: 10px;
    }
  }
}

.flexed {
  display: flex;
  flex-direction: row;
  align-items: center;
  .xform_input {
    margin-right: 16px;
    width: 100%;
    &:nth-child(3) {
      width: 50% !important;
    }
    &:nth-child(4) {
      width: 50% !important;
    }
    &:last-child {
      margin-right: 0px !important;
    }
  }
}

.xselect {
  width: 180px;
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
  transition: all 0.3s ease;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
  }
  select {
    border: none;
    outline: none;
    width: 100%;
  }
  &:focus {
    border: 1px solid #bfbfbf;
  }
}

.xform {
  width: 100%;
  margin-top: 4px;
  .xform_group {
    border-top: 1px solid #f1f1f1;
    padding-top: 12px;
    margin-bottom: 24px;
    .xform_input {
      margin-bottom: 10px !important;
    }
  }
  .xform_input {
    margin-bottom: 16px;
    width: 100%;
    max-width: 600px;
    position: relative;
    label {
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
      color: #535353;
    }
    &.bigly {
      label {
        font-size: 18px !important;
        margin-bottom: 12px;
      }
    }
    .xform_input__action2 {
      position: absolute;
      top: 0;
      right: 30px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 4px;
      padding: 4px 4px 4px;
      line-height: 1;
      transition: all 0.3s ease;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .xform_input__action {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 4px;
      padding: 0px 4px 4px;
      line-height: 1;
      transition: all 0.3s ease;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    select {
      border: none;
      outline: none;
      appearance: none;
    }
    .selc {
      margin-top: 2px;
      display: block;
      width: fit-content !important;
    }
    input,
    textarea,
    span.selc {
      width: 100%;
      border: 1px solid #dadada;
      border-radius: 6px;
      padding: 8px 16px;
      outline: none;
      transition: all 0.3s ease;
      &:focus {
        border: 1px solid #bfbfbf;
      }
    }
  }
}

.images_list__twice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-right: 12px;
  .img_item {
    margin-bottom: 0px !important;
  }
  .ilt_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 10px 30px 4px 10px;
    margin-bottom: 10px;
    position: relative;
  }
  .acts {
    position: absolute;
    top: 6px;
    right: 7px;
    cursor: pointer;
    opacity: 0.4;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }
  .ilt_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 8px;
    padding: 0px 22px 0px 0px;
    margin-right: 6px;
    margin-bottom: 8px;
    position: relative;
    &:last-child {
      margin-right: 0px;
    }
    &.wbg {
      padding: 8px 22px 8px 10px;
      background-color: rgba(0, 0, 0, 0.07);
    }
    .img_item__img {
      width: 56px;
      min-width: 56px;
      height: 56px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
      margin-right: 6px;
      // &:nth-child(2) {
      //   margin-right: 0px;
      // }
      .mdp {
        background-position: center;
        width: 100%;
        height: 56px;
        border-radius: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        cursor: pointer;
        span {
          background-color: rgba(0, 0, 0, 0.2);
          color: rgba(255, 255, 255, 0.4);
          width: 100%;
          height: 56px;
          display: block;
          top: 0;
          margin-top: 0;
          padding-top: 16px;
          border-radius: 10px;
          font-size: 20px;
          transition: all 0.4s ease;
        }
        &:hover {
          span {
            background-color: rgba(0, 0, 0, 0.6);
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
}

.images_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .img_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    &.upload {
      span {
        background-color: rgba(0, 0, 0, 0.08) !important;
        color: #000 !important;
      }
    }
    .img_item__img {
      width: 56px;
      min-width: 56px;
      height: 56px;
      border-radius: 10px;
      margin-right: 12px;
      .mdp {
        background-position: center;
        width: 100%;
        height: 56px;
        border-radius: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        cursor: pointer;
        span {
          background-color: rgba(0, 0, 0, 0.2);
          color: rgba(255, 255, 255, 0.4);
          width: 100%;
          height: 56px;
          display: block;
          top: 0;
          margin-top: 0;
          padding-top: 16px;
          border-radius: 10px;
          font-size: 20px;
          transition: all 0.4s ease;
        }
        &:hover {
          span {
            background-color: rgba(0, 0, 0, 0.6);
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
    .img_item__input {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

.images_bl {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 10px 12px 12px;
  width: fit-content;
  position: relative;
  cursor: pointer;
  min-width: calc(33.3% - 16px);
  width: calc(33.3% - 16px);
  margin-right: 16px;
  max-width: 100%;
  background-color: #fff;
  &:nth-child(3n) {
    min-width: 33.3%;
    width: 33.3%;
    margin-right: 0px;
  }
  .images_bl__del {
    position: absolute;
    opacity: 0.2;
    transition: all 0.3s ease;
    right: 12px;
    top: 6px;
    z-index: 9999999;
    &:hover {
      opacity: 0.8;
    }
  }
  .images_bl__edit {
    position: absolute;
    opacity: 0.2;
    transition: all 0.3s ease;
    right: 40px;
    top: 6px;
    z-index: 9999999;
    &:hover {
      opacity: 0.8;
    }
  }
  .images_bl__type {
    font-size: 14px;
    opacity: 0.6;
    margin-bottom: 4px;
  }
  .txt {
    div {
      &:nth-child(1) {
        font-size: 16px;
        font-weight: 500;
      }
      &:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        height: 60px;
        overflow: hidden;
      }
    }
  }
  .images_bl__images {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    margin-top: 10px;
    div {
      width: 56px;
      min-width: 56px;
      height: 56px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
      margin-right: 12px;
    }
  }
}

.xuploader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px;
  .xupload_data {
    width: 100%;
    h4 {
      font-size: 20px;
      font-weight: 700;
    }
    p {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
      line-height: 1;
      small {
        opacity: 0.5;
      }
    }
  }
}

.ximages {
  position: relative;
  margin-top: 24px;
  .ximages_add {
    position: absolute;
    right: 0;
    top: -52px;
    button {
      width: fit-content;
      text-align: center;
      border-radius: 8px;
      color: rgb(47, 47, 47);
      background-color: #f0f0f0;
      position: relative;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 400;
      z-index: 99;
      padding: 8px 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      transition: all 0.3s ease;
      margin-right: 12px;
      &:hover {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }
      &:active {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .ximages_block {
    background-color: #f2f2f2;
    //box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 20px;
    position: relative;
    .ximages_delete {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 14px;
      cursor: pointer;
      opacity: 0.6;
      &:hover {
        text-decoration: underline;
      }
    }
    .xuploader {
      background-color: #e9e9e9;
      margin-bottom: 0px !important;
      margin-top: 16px;
      .xupload_data {
        width: 200px;
        min-width: 200px;
        margin-right: 12px;
        small {
          opacity: 0.5;
        }
      }
      .xupload_items {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .xupload_image {
          width: 64px;
          height: 64px;
          min-width: 64px;
          border-radius: 12px;
          margin-right: 16px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          .delete {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            top: 2px;
            right: 2px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              background-color: #000;
            }
          }
        }
      }
    }
  }
}

.xuploaddd {
  div:not(.xupload) {
    border: none !important;
    background-color: transparent !important;
    margin-bottom: 0px !important;
    span {
      padding: 0 !important;
    }
  }
}

.xupload {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px;
  border: none !important;
  .xupload_image {
    width: 110px;
    height: 110px;
    min-width: 110px;
    border-radius: 12px;
    margin-right: 16px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1 !important;
    }
  }
  .xupload_data {
    width: 100%;
    h4 {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    p {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0px;
      text-align: left;
      small {
        opacity: 0.5;
        font-weight: 300 !important;
      }
    }
  }
}

.xgrid_wrap {
  position: relative;
  .actsx {
    position: absolute;
    top: 4px;
    right: 0px;
    button {
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0.07);
      color: rgba(0, 0, 0, 0.5);
      border-radius: 100px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 0.5;
      margin-right: 12px;
      transition: all 0.3s ease;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  h4 {
    cursor: pointer;
    border-bottom: 1px solid rgb(0 0 0 / 11%);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 16px;
    height: 52px;
  }
}

.xgrid_logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 24px 0;
  .xgrid_logos__item {
    width: calc(25% - 16px);
    margin-right: 16px;
    position: relative;
    cursor: move;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    p {
      font-size: 14px !important;
      overflow: hidden;
      padding: 14px 20px 14px 14px;
      width: 100% !important;
      margin-bottom: 0px !important;
    }
    &:nth-child(4n) {
      width: 25% !important;
      margin-right: 0px !important;
    }
    &:hover {
      .xgrid_logos__item__actions {
        opacity: 1 !important;
      }
      .xgrid_logos__item__img {
        div {
          &:nth-child(2) {
            opacity: 1 !important;
          }
        }
      }
    }
    .xgrid_logos__item__img {
      height: 120px;
      div {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        &:nth-child(1) {}
        &:nth-child(2) {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: all 0.4s ease;
        }
      }
    }
    .xgrid_logos__item__actions {
      opacity: 0;
      transition: all 0.4s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        button {
          background-color: #fff !important;
          color: #000 !important;
          padding: 4px 10px 6px !important;
          margin-right: 8px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

.xdesc {
  width: 100% !important;
  opacity: 0.6 !important;
  font-size: 14px !important;
}

.flexed_imgs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  max-width: 736px;
  flex-wrap: wrap;
  div {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    margin-right: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    &.fximgx {
      margin-right: 24px;
      &::after {
        width: 1px;
        height: 100%;
        position: absolute;
        right: -12px;
        content: " ";
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    span {
      display: block;
      position: absolute;
      top: 2px;
      right: 2px;
      border-radius: 100px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      padding-top: 5px;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease;
      &:hover {
        background-color: #000;
      }
    }
  }
}

.xtable {
  width: 100%;
  .xtable_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 6px;
    div {
      font-size: 14px;
      color: #848484;
      &:nth-child(1) {
        width: 50px;
        min-width: 50px;
      }
      &:nth-child(2) {
        width: 180px;
        min-width: 180px;
      }
      &:nth-child(3) {
        width: 100%;
        min-width: 230px;
      }
      &:nth-child(4) {
        width: 130px;
        min-width: 130px;
      }
      &:nth-child(5) {
        width: 120px;
        min-width: 120px;
      }
    }
  }
  .xtable_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .xlist_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 6px 0;
      cursor: pointer;
      &:hover {
        div {
          &:nth-child(2) {
            span {
              //text-decoration: underline;
              border-bottom: 1px dashed;
            }
          }
        }
      }
      div {
        &.xlist_item__actions {
          button {
            width: 34px !important;
            border: none;
            outline: none;
            border-radius: 100px;
            padding: 4px 8px 3px !important;
            color: #818181 !important;
            transition: all 0.3s ease;
            box-shadow: none !important;
            background-color: #dbdbdb !important;
            margin-right: 12px !important;
            &:last-child {
              margin-right: 0px !important;
            }
            &:hover {
              color: #000 !important;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05) !important;
            }
          }
        }
        &:nth-child(1) {
          width: 50px;
          min-width: 50px;
          opacity: 0.6;
        }
        &:nth-child(2) {
          width: 180px;
          min-width: 180px;
        }
        &:nth-child(3) {
          width: 100%;
          min-width: 230px;
          opacity: 0.6;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:nth-child(4) {
          width: 130px;
          min-width: 130px;
          opacity: 0.6;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:nth-child(5) {
          width: 120px;
          min-width: 120px;
        }
      }
    }
  }
}

.manager_sidebar__mn {
  display: none;
}

.xblocklist {
  max-width: 700px;
  .xblocklist_item {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 10px 16px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
    .xblocklist_item__title {
      color: #000;
      font-family: "TTFors";
      font-size: 16px;
      font-weight: 500;
      span {
        color: rgba(0, 0, 0, 0.4);
      }
    }
    .xblocklist_item__text {
      color: #000;
      font-family: "TTFors";
      font-size: 14px;
      font-weight: 400;
      max-height: 44px;
      overflow: hidden;
      p {
        margin-bottom: 0px;
        font-size: 14px !important;
        white-space: pre;
        font-family: "TTFors";
        br {
          display: none;
        }
      }
    }
    .xblocklist_item__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 9px;
      right: 16px;
      div {
        cursor: pointer;
        margin-right: 12px;
        color: rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          color: rgba(0, 0, 0, 0.8)
        }
        svg {}
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .manager_sidebar__menu {
    div {
      font-size: 15px !important;
    }
  }
  .manager_sidebar_cwp {
    font-size: 15px !important;
  }
  .manager_sidebar__logout {
    font-size: 15px !important;
  }
  .xgrid {
    .xgrid_item {
      min-width: calc(50% - 16px) !important;
      width: calc(50% - 16px) !important;
      margin-right: 16px !important;
      &:nth-child(2n) {
        width: 50% !important;
        min-width: 50% !important;
        margin-right: 0px !important;
      }
    }
  }
  .xactions {
    .xactions_item {
      font-size: 15px !important;
      padding: 8px 16px !important;
    }
  }
  .mobbed {
    width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 30px !important;
  }
  .manager_body {
    width: calc(100% - 300px) !important;
  }
  .images_bl {
    width: calc(50% - 16px) !important;
    min-width: calc(50% - 16px) !important;
    margin-right: 16px !important;
    &:nth-child(2n) {
      width: 50% !important;
      min-width: 50% !important;
      margin-right: 0px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .xgrid {
    .xgrid_item {
      min-width: calc(100%) !important;
      width: calc(100%) !important;
      margin-right: 0px !important;
      &:nth-child(2n) {
        width: 100% !important;
        min-width: 100% !important;
        margin-right: 0px !important;
      }
    }
  }
  .images_bl {
    width: 100% !important;
    min-width: 100% !important;
    margin-right: 0px !important;
    &:nth-child(2n) {
      width: 100% !important;
      min-width: 100% !important;
      margin-right: 0px !important;
    }
  }
  .manager_body {
    width: 100% !important;
  }
  .xtable_wrap {
    width: 100%;
    overflow: scroll;
  }
  .manager {
    padding: 12px !important;
    .manager_wrap {
      flex-direction: column !important;
    }
    .manager_sidebar {
      width: 100% !important;
      border-bottom-left-radius: 0px !important;
      border-top-right-radius: 12px !important;
      height: unset !important;
      .manager_sidebar__logo {
        padding: 19px 18px 16px !important;
        line-height: 1 !important;
      }
      .manager_sidebar__menu {
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        overflow: scroll;
        margin-top: 0px !important;
        height: 0px;
        transition: all 0.4s ease;
        &.openned {
          height: 270px;
        }
      }
      .manager_sidebar_cwp {
        display: none;
      }
      .manager_sidebar__logout {
        bottom: unset !important;
        top: 17px;
        right: 0px;
        font-size: 16px !important;
        display: none;
      }
      .manager_sidebar__mn {
        display: block;
        position: absolute;
        top: 16px;
        cursor: pointer;
        right: 30px;
        z-index: 99999999;
        transition: all 0.4s ease;
        svg {
          rect {
            transition: all 0.3s ease;
            transform: translateY(0px) translateX(0px) rotate(0deg);
          }
        }
        &.openned {
          svg {
            rect {
              &:nth-child(1) {
                transform: translateY(15px) translateX(-2px) rotate(-45deg);
              }
              &:nth-child(2) {
                transform: translateY(-4px) translateX(12px) rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
  .xactions {
    position: unset !important;
    margin-bottom: 12px !important;
  }
  .manager_body__content {
    height: calc(100% - 93px) !important;
  }
  .manager_body {
    height: calc(100% - 112px) !important;
  }
  .xgrid {
    flex-direction: column !important;
    .xgrid_item {
      width: 100% !important;
      padding-right: 0px !important;
    }
  }
  .flexed {
    flex-direction: column !important;
    .xform_input {
      width: 100% !important;
      min-width: 100% !important;
      margin-right: 0px !important;
    }
  }
  .xupload {
    h4 {
      font-size: 15px !important;
    }
    p {
      font-size: 12px !important;
    }
  }
  .xtabs {
    max-width: 100%;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .mobbed {
    margin-bottom: 20px;
  }
}